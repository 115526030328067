// extracted by mini-css-extract-plugin
export var desc = "shopify-module--desc--3Rrxh";
export var descProject = "shopify-module--descProject--3BPcD";
export var descProjectLast = "shopify-module--descProjectLast--Y-Qjz";
export var header = "shopify-module--header--1FRqc";
export var clientInput = "shopify-module--clientInput--18tvL";
export var response = "shopify-module--response--21gf1";
export var textbox = "shopify-module--textbox--2Kna6";
export var shopMain = "shopify-module--shopMain--1H_13";
export var container = "shopify-module--container--ur85q";
export var prompt = "shopify-module--prompt--2ihvE";
export var submitButton = "shopify-module--submitButton--2qjUJ";
export var rightTxt = "shopify-module--rightTxt--3pjaJ";
export var line = "shopify-module--line--3C3Sr";
export var paraName = "shopify-module--paraName--2yxMk";